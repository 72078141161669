<template>
  <div>
    <a-button-group size="small">
      <a-button @click="handleEdit">编辑</a-button>
      <a-popconfirm title="确定删除吗?" @confirm="handleDelete">
        <a-button type="danger">删除</a-button>
      </a-popconfirm>
    </a-button-group>
  </div>
</template>

<script>
import { processInspectionReportDestroy } from "@/api/inspection";

export default {
  components: {},
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    handleEdit() {
      this.$router.push({ path: "/inspection/process_inspection_report_update", query: { id: this.item.id } });
    },
    handleDelete() {
      processInspectionReportDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("delete", this.item);
      });
    },
  },
};
</script>

<style scoped></style>
